// Галерея-файлообменник

import React from "react";

import {
  Form,
  Row,
  Col,
  Divider,
  Input,
  List,
  PageHeader,
  Card,
  Button,
  Image,
  Tag,
  Upload,
  message,
  Spin,
  Badge,
} from "antd";
import {
  CommentOutlined,
  DownloadOutlined,
  SendOutlined,
  EditOutlined,
  EllipsisOutlined,
  LikeOutlined,
  SettingOutlined,
} from "@ant-design/icons";
import { useEffect, useState, useRef } from "react";
import TextArea from "antd/lib/input/TextArea";
import "rc-collapse/assets/index.css";
import { jsParseDate } from "../lib/func";
import { generateNewId, upd_date, getUserById } from "../lib/apiReq";
import { Header } from "antd/lib/layout/layout";
import Modal from "antd/lib/modal/Modal";
import PhotoPreview from "./PhotoPreview";
import { Lightbox } from "react-modal-image";
const { Meta } = Card;

const GalleryPage = () => {
  useEffect(() => {
    // загрузка списка пещер
    readFromDb();
    //получить текущего пользователя
    setUser(getUserById());
  }, []);

  const [showDescriptionBar, setShowDescriptionBar] = useState(false);

  const [file, setFile] = useState(null);

  const [user, setUser] = useState(null);

  const [spinner, setSpinner] = useState(false);

  const contentRef = useRef(null);

  const [isPreviewVisible, setIsPreviewVisible] = useState(false);

  const [srcLink, setSrcLink] = useState("");

  const [srcTitle, setSrcTitle] = useState("title");

  const [galleryList, setGalleryList] = useState(null);

  // настройки загрузчика для фоток

  const props = {
    name: "image",
    listType: "photo",
    maxCount: 1,
    action: "https://followmytrack.ru/upload_img.php",

    onChange(info) {
      if (info.file.status !== "uploading") {
        console.log(
          "imgs->",
          info.fileList.map((el) => el.response.link).join()
        );
        if (info.fileList.length > 0) {
          console.log(info.fileList[0].response.link);
          setShowDescriptionBar(true);
          setFile(info.fileList[0].response.link);
        }
      }
      if (info.file.status === "done") {
        message.success(`${info.file.name} file uploaded successfully`);
      } else if (info.file.status === "error") {
        message.error(`${info.file.name} file upload failed.`);
      }
    },
  };

  // Загрузка файлов галлереи
  const readFromDb = async () => {
    setSpinner(true);
    try {
      const response = await fetch("https://followmytrack.ru/gallery.php");
      const result = await response.json();
      setGalleryList(result);
      console.log("Gallery Dowmloaded", result);
    } catch (error) {
      console.error(`Download error: ${error.message}`);
    }
    setSpinner(false);
  };

  // Добавить новый файл в галерею
  const addToGallery = async () => {
    // Генерация даты
    const upd_date = new Date().toLocaleString("ru", {
      year: "numeric",
      month: "short",
      day: "numeric",
      timezone: "UTC",
      hour: "numeric",
      minute: "numeric",
    });

    // Уникальный id материала

    const id =
      Math.random().toString(36).substring(2, 10) +
      Math.random().toString(36).substring(2, 10);

    // Тег
    const tag = document.forms[0].elements[0].value;

    // Описание файла
    const description = document.forms[0].elements[1].value;

    // Ссылки на добавленные фото
    const filename = file;

    var formdata = new FormData();

    formdata.append("tag", tag);
    formdata.append("file", filename);
    formdata.append("type", "photo");
    formdata.append("date", upd_date);
    formdata.append("description", description);
    formdata.append("id", id);

    var requestOptions = {
      method: "POST",
      body: formdata,
      redirect: "follow",
    };

    console.log(requestOptions);

    fetch("https://followmytrack.ru/gallery.php", requestOptions)
      .then((response) => response.text())
      .then((result) => readFromDb())
      .catch((error) => console.log("error", error));

    setShowDescriptionBar(false);
  };

  return (
    <div className="alphaback">
      {/* <PhotoPreview src={srcLink} title={srcTitle} visible={isPreviewVisible} /> */}

      {/* Лайтбокс галлереи */}
      {isPreviewVisible && (
        <Lightbox
          alt={srcTitle}
          style={{ paddingTop: "10px" }}
          onClose={() => setIsPreviewVisible(false)}
          medium={srcLink}
        />
      )}

      <PageHeader
        className="site-page-header"
        style={{ width: "100%", paddingBottom: "10px" }}
        extra={<></>}
        title="Галерея"
      />

      {showDescriptionBar == false ? (
        <div>
          {localStorage.getItem("userAttribute") == 1 ? (
            <Upload {...props} style={{ width: "80%" }}>
              <a>
                <b>+ файл</b>
              </a>
            </Upload>
          ) : (
            <Upload {...props} style={{ width: "80%" }}>
              <a>
                <b>+ файл</b>
              </a>
            </Upload>
          )}
        </div>
      ) : null}

      {showDescriptionBar && (
        <>
          <div
            style={{
              margin: "2px",
              // border: "thin solid black",
              backgroundColor: "white",
            }}
          >
            <Form
              name="basic"
              labelCol={{
                span: 8,
              }}
              wrapperCol={{
                span: 24,
              }}
              style={{
                border: "solid",
                padding: "5px",
              }}
              initialValues={{
                remember: true,
              }}
              onFinish={addToGallery}
              onFinishFailed={() => alert("Ошибка данных")}
              autoComplete="off"
            >
              <Row justify="space-around">
                <Col flex="none">
                  {file && (
                    <div style={{ padding: "5px" }}>
                      <Image
                        width={200}
                        placeholder
                        alt="photo"
                        src={`${file}_min.jpg`}
                      />
                      <span>{file.split("/").pop()} </span>
                    </div>
                  )}
                </Col>
                <Col flex="none">
                  <Divider orientation="left">Описание файла</Divider>
                  <Form.Item
                    name={["tag", "tag_file"]}
                    label="Тег"
                    required
                    extra="Категория файла или название альбома"
                  >
                    <Input style={{ width: "100%" }} />
                  </Form.Item>
                  <Form.Item
                    name={["description", "description_file"]}
                    label="Описание"
                    required
                    extra="Кратка о том что содержит файл"
                  >
                    <Input style={{ width: "100%" }} />
                  </Form.Item>
                </Col>
              </Row>
              <Divider orientation="left">Все готово?</Divider>
              <Form.Item wrapperCol={{ offset: 9 }}>
                <Button
                  icon={<SendOutlined />}
                  htmlType="submit"
                  // onClick={() => alert(topoImgUrls)}
                >
                  Сохранить
                </Button>
              </Form.Item>
            </Form>
          </div>
          <br />
          <br />
        </>
      )}

      {galleryList !== null ? (
        <div
          style={{
            margin: "5px",
            padding: "8px",
            borderRadius: "3px",
            paddingTop: "15px",
            border: "thin solid black",
            backgroundColor: "white",
          }}
        >
          <List
            grid={{
              gutter: 8,
              xs: 2,
              sm: 3,
              md: 4,
              lg: 5,
              xl: 5,
              xxl: 5,
            }}
            dataSource={galleryList}
            renderItem={(item) => (
              <List.Item>
                <Badge.Ribbon text={item.tag} color="volcano">
                  <Card
                    // className="image-card"
                    bodyStyle={{ padding: "1px" }}
                    // style={{ width: "140px", padding: "1px", marginLeft: "3px" }}
                    cover={
                      <div>
                        <Image
                          placeholder
                          alt="photo"
                          src={`${item.file}_min.jpg`}
                          onClick={() => {
                            setSrcLink(item.file);
                            setSrcTitle(item.description);
                            setIsPreviewVisible(true);
                            //   window.scrollTo(0, 0);
                          }}
                          //  onMouseLeave={() => setIsPreviewVisible(false)}
                          preview={{
                            visible: false,
                            src: item.file,
                          }}
                        />
                      </div>
                    }
                  >
                    {item.date} <br />
                    <font size="2">
                      {item.description.substring(0, 14) + "..."}
                    </font>{" "}
                  </Card>
                </Badge.Ribbon>
              </List.Item>
            )}
          />{" "}
        </div>
      ) : (
        <Spin />
      )}
    </div>
  );
};

export default GalleryPage;
