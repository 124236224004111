// сообщения - мессенджер

import {
  PageHeader,
  Card,
  List,
  Avatar,
  Badge,
  Modal,
  Button,
  Form,
  Input,
  Divider,
  Space,
  Image,
  message,
  Upload,
} from "antd";

import {
  ArrowLeftOutlined,
  SendOutlined,
  RotateRightOutlined,
  SwapOutlined,
  RotateLeftOutlined,
  ZoomOutOutlined,
  ZoomInOutlined,
  UploadOutlined,
  UserOutlined,
  CommentOutlined,
} from "@ant-design/icons";
import { useEffect, useRef, useState } from "react";
import TextArea from "antd/lib/input/TextArea";
import { generateNewId, getUserById } from "../lib/apiReq";
import { jsParseDate } from "../lib/func";

const InfoPage = () => {
  const { Meta } = Card;

  useEffect(() => {
    readFromDb();
    // загрузка списка пользователей
    readUsersList();
    // загрузка списка сообщений
    setInterval(() => readFromDb(), 10000);
    // получить текущего пользователя
    getAsyncUserById();
  }, []);

  const [spinner, setSpinner] = useState(false);
  const [messageList, setMessageList] = useState("");
  const [usersList, setUsersList] = useState("");
  const [user, setUser] = useState(null);
  const [textMessage, setTextMessage] = useState("");
  const [imageUrl, setImageUrl] = useState("");
  const [reboot, setReboot] = useState("");
  const [randomKey, setRandomKey] = useState(0);
  const messageInput = useRef(null);

  // настройки загрузчика фотографий
  const props = {
    name: "image",
    listType: "picture",
    maxCount: 1,
    action: "https://followmytrack.ru/upload_img.php",

    onChange(info) {
      if (info.file.status !== "uploading") {
        console.log(
          "imgs->",
          info.fileList.map((el) => el.response.link).join()
        );
        if (info.fileList.length > 0)
          setImageUrl(info.fileList.map((el) => el.response.link).join());
      }
      if (info.file.status === "done") {
        message.success(`${info.file.name} file uploaded successfully`);
      } else if (info.file.status === "error") {
        message.error(`${info.file.name} file upload failed.`);
      }
    },
  };

  // асинхронная загрузка данных текущего пользователя

  const getAsyncUserById = async () =>
    setUser(await getUserById(localStorage.getItem("userId")));

  // есть ли непрочитанное сообщение по id user

  const getUnreadFromId = (id, list) => {
    return list.filter((el) =>
      el.senderId == id && el.recepientId == user[0].id
        ? el.isRead == 0 && el.id
          ? true
          : false
        : false
    );
  };

  // сделать сообщение "прочитанным" между собеседниками

  const readMessagesFlag = (messagesId) => {
    console.log("messageId->", messagesId);
    var formdata = new FormData();

    formdata.append("id", messagesId);
    formdata.append("field_name", "isRead");
    formdata.append("field_data", 1);

    var requestOptions = {
      method: "POST",
      body: formdata,
      redirect: "follow",
    };

    fetch("https://followmytrack.ru/messageEdit.php", requestOptions)
      .then((response) => response.text())
      .then((result) => readFromDb())
      .catch((error) => console.log("error", error));

    readFromDb();
  };

  // выбранный собеседник

  const [selectRecepientData, setSelectRecepientData] = useState(null);

  // прочитать данные сообщений из БД
  const readFromDb = async () => {
    setSpinner(true);
    try {
      const response = await fetch("https://followmytrack.ru/messages.php");
      const result = await response.json();
      // добавить к стейту с сортировкой по дате
      setMessageList(
        result
          .slice(0)
          .sort((a, b) => jsParseDate(b.date).date - jsParseDate(a.date).date)
      );
      console.log("Messages Downloaded", result);
    } catch (error) {
      console.error(`Download error: ${error.message}`);
    }
    setSpinner(false);
  };

  // прочитать список всех зарегистрированных пользователей
  const readUsersList = async () => {
    setSpinner(true);
    try {
      const response = await fetch("https://followmytrack.ru/getUsers.php");
      const result = await response.json();
      setUsersList(result);
      console.log("Users Downloaded", result);
    } catch (error) {
      console.error(`Download error: ${error.message}`);
    }
    setSpinner(false);
  };

  // добавить новое сообщение
  const sendMessage = async (message, s_id, r_id) => {
    // генерация даты
    const upd_date = new Date().toLocaleString("ru", {
      year: "numeric",
      month: "short",
      day: "numeric",
      timezone: "UTC",
      hour: "numeric",
      minute: "numeric",
      second: "numeric",
    });

    console.log("send_date->", upd_date);

    // Уникальный id материала
    const id =
      Math.random().toString(36).substring(2, 10) +
      Math.random().toString(36).substring(2, 10);

    var formdata = new FormData();

    formdata.append("image", imageUrl);
    formdata.append("message", message);
    formdata.append("senderId", s_id);
    formdata.append("recepientId", r_id);
    formdata.append("date", upd_date);
    formdata.append("id", id);

    var requestOptions = {
      method: "POST",
      body: formdata,
      redirect: "follow",
    };

    fetch("https://followmytrack.ru/messages.php", requestOptions)
      .then((response) => response.text())
      .then((result) => readFromDb())
      .catch((error) => console.log("error", error));

    // сброс Upload после отправки сообщения

    setImageUrl("");
    setRandomKey(Math.random(100));

    readFromDb();
  };

  return (
    <div className="alphaback">
      <PageHeader
        className="site-page-header"
        style={{ width: "100%", paddingBottom: "10px" }}
        title={
          <Space align="end">
            {selectRecepientData && (
              <a onClick={() => setSelectRecepientData(null)}>
                <ArrowLeftOutlined />
              </a>
            )}
            <span>Переписка</span>
          </Space>
        }
      />
      {/* Отображение текущего пользователя */}
      {!selectRecepientData && (
        <div>
          <Card
            loading={!user}
            style={{
              borderRadius: "3px",
              backgroundColor: "#FFF5EE",
              padding: "16px",
            }}
            bodyStyle={{
              padding: "0px",
            }}
          >
            {user && (
              <Meta
                avatar={
                  user[0].photo ? (
                    <Avatar src={user[0].photo} />
                  ) : (
                    <Avatar icon={<UserOutlined />} />
                  )
                }
                title={user[0].name}
                description={user[0].email}
              />
            )}
          </Card>
          <br />
        </div>
      )}

      {/* Отображение собеседников */}
      {!selectRecepientData ? (
        <Card
          style={{
            borderRadius: "3px",
            backgroundColor: "#FFF5EE",
            padding: "16px",
          }}
          bodyStyle={{
            padding: "0px",
          }}
        >
          <List
            dataSource={usersList}
            loading={!user}
            renderItem={(item) => (
              <List.Item>
                <p>
                  <Badge
                    count={
                      messageList &&
                      user &&
                      getUnreadFromId(item.id, messageList).length
                    }
                    size="small"
                  >
                    {item.photo ? (
                      <Avatar src={item.photo} />
                    ) : (
                      <Avatar icon={<UserOutlined />} />
                    )}
                  </Badge>

                  <span> </span>
                  {item.name}
                </p>

                {/* <p>{item.email}</p> */}
                <Button
                  type="primary"
                  shape="circle"
                  icon={<SendOutlined />}
                  onClick={() => setSelectRecepientData(item)}
                />
              </List.Item>
            )}
          />
        </Card>
      ) : (
        <>
          <Card
            style={{
              borderRadius: "3px",
              backgroundColor: "#FFF5EE",
              padding: "16px",
            }}
            bodyStyle={{
              padding: "0px",
            }}
          >
            <Meta
              avatar={
                selectRecepientData.photo ? (
                  <Avatar src={selectRecepientData.photo} />
                ) : (
                  <Avatar icon={<UserOutlined />} />
                )
              }
              title={selectRecepientData.name}
              description={selectRecepientData.email}
            />
          </Card>

          <br />
          <Card
            style={{
              borderRadius: "3px",
              backgroundColor: "#FFF5EE",
              padding: "16px",
            }}
            bodyStyle={{
              padding: "0px",
            }}
          >
            <TextArea
              onKeyDown={(e) => {
                if (e.keyCode == 13) {
                  e.preventDefault();
                  sendMessage(textMessage, user[0].id, selectRecepientData.id);
                  messageInput.current.focus();

                  setReboot("");
                  messageInput.current.focus();
                }
              }}
              value={reboot}
              ref={messageInput}
              onChange={(e) => {
                setReboot(e.target.value);
                setTextMessage(e.target.value);
              }}
              size="middle"
            />
            <br />
            <Upload key={randomKey} {...props}>
              <a>
                <b>+фото</b>
              </a>
            </Upload>
            <br />
            <Button
              onKeyDown={(e) => {
                if (e.keyCode == 13) {
                  sendMessage(textMessage, user[0].id, selectRecepientData.id);
                  messageInput.current.focus();

                  setReboot("");
                  messageInput.current.focus();
                }
              }}
              type="primary"
              icon={<SendOutlined />}
              onClick={() => {
                sendMessage(textMessage, user[0].id, selectRecepientData.id);
                messageInput.current.focus();

                setReboot("");
                messageInput.current.focus();
              }}
            >
              Отправить
            </Button>
          </Card>
          <br />
        </>
      )}
      {selectRecepientData &&
        messageList &&
        messageList.map((el) => (
          <div>
            {/* Отобразить сообщение если отправитель - этот пользователь */}
            {el.senderId == user[0].id &&
              el.recepientId == selectRecepientData.id && (
                <Card
                  style={{
                    marginBottom: "10px",
                    marginLeft: "35%",
                    borderRadius: "5px",
                    backgroundColor: "#FFFFE0",
                  }}
                  bodyStyle={{
                    padding: "16px",
                  }}
                >
                  <Badge dot={el.isRead == "0"}>
                    <Meta
                      avatar={
                        user[0].photo ? (
                          <Avatar src={user[0].photo} />
                        ) : (
                          <Avatar icon={<UserOutlined />} />
                        )
                      }
                      title={user[0].name}
                      description={el.date}
                    />
                  </Badge>
                  <br />
                  <span>{el.message}</span>
                  {el.image && (
                    <>
                      {" "}
                      <br />
                      <Image
                        width="200px"
                        src={`${el.image}_min.jpg`}
                        preview={{ src: el.image }}
                      />
                    </>
                  )}
                </Card>
              )}
            {/* Отобразить сообщение если отправитель - собеседник */}

            {el.senderId == selectRecepientData.id &&
              el.recepientId == user[0].id && (
                <Card
                  style={{
                    marginBottom: "10px",
                    borderRadius: "5px",
                    marginRight: "35%",
                    backgroundColor: "#E0FFFF",
                  }}
                  bodyStyle={{
                    padding: "16px",
                  }}
                  onClick={() => readMessagesFlag(el.id)}
                >
                  <Badge dot={el.isRead == "0"}>
                    <Meta
                      avatar={
                        selectRecepientData.photo ? (
                          <Avatar src={selectRecepientData.photo} />
                        ) : (
                          <Avatar icon={<UserOutlined />} />
                        )
                      }
                      title={selectRecepientData.name}
                      description={el.date}
                    />
                  </Badge>
                  <br />
                  <span>{el.message}</span>
                  {el.image && (
                    <>
                      {" "}
                      <br />
                      <Image
                        width="200px"
                        src={`${el.image}_min.jpg`}
                        preview={{ src: el.image }}
                      />
                    </>
                  )}
                </Card>
              )}
          </div>
        ))}
    </div>
  );
};

export default InfoPage;
